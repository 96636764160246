import React, { cloneElement, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Hidden,
  Link,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ReactComponent as Logo } from "../../../assets/images/HKScan.svg";
import KivinettiLogo from "../../../assets/images/kivinetti.png";
import { ReactComponent as FacebookIcon } from "../../../assets/images/iconmonstr-facebook-4.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/images/iconmonstr-instagram-14.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/images/iconmonstr-twitter-4.svg";
import { ReactComponent as YouTubeIcon } from "../../../assets/images/iconmonstr-youtube-4.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/images/iconmonstr-linkedin-4.svg";
import { ReactComponent as HKScanEnvironmentLeft } from "../../../assets/images/hkscan_environment_left.svg";
import { ReactComponent as HKScanEnvironmentRight } from "../../../assets/images/hkscan_environment_right.svg";
import KivinettiEnvironmentLeft from "../../../assets/images/kivinetti_environment_left.png";
import KivinettiEnvironmentRight from "../../../assets/images/kivinetti_environment_right.png";
import CooperativeFooterImage from "../../../assets/images/Omistajatuottajat_LSO_Osuuskunta_nega.png";
import useStyles from "./FooterStyles";
import { t } from "../../services/langService";
import { useSelector } from "react-redux";
import { OperationUnit } from "../../enums/CoreEnums";
import ProductionArea from "../../enums/productionLines";
import { RootState } from "../../store/rootReducer";

const hkscanSocialMediaLinks = [
  {
    name: "Facebook",
    icon: <FacebookIcon />,
    link: "https://www.facebook.com/HKHooKoo",
  },
  {
    name: "Instagram",
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/momentswithhkfoods/",
  },
  {
    name: "Twitter",
    icon: <TwitterIcon />,
    link: "https://twitter.com/HKFoods_Plc",
  },
  {
    name: "YouTube",
    icon: <YouTubeIcon />,
    link: "https://www.youtube.com/@hkfoods757",
  },
  {
    name: "LinkedIn",
    icon: <LinkedInIcon />,
    link: " https://www.linkedin.com/company/hkfoods-oyj/",
  },
];

const kivinettiSocialMediaLinks = [
  {
    name: "Facebook",
    icon: <FacebookIcon />,
    link: "https://www.facebook.com/Kivikyl%C3%A4n-Kotipalvaamo-160349827322001",
  },
  {
    name: "Instagram",
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/kivikylan/",
  },
  {
    name: "LinkedIn",
    icon: <LinkedInIcon />,
    link: "https://www.linkedin.com/company/kivikyl%C3%A4n-kotipalvaamo-oy/mycompany/",
  },
];

const sinettiTranslations = {
  firmNameTerm: "footer_firmName",
  infoline1: "footer_infoLine1",
  infoline2: "footer_infoLine2",
  infoline3: "footer_infoLine3",
};

const kivinettiTranslations = {
  firmNameTerm: "footer_firmName_kivinetti",
  infoline1: "footer_infoLine1_kivinetti",
  infoline2: "footer_infoLine2_kivinetti",
  infoline3: "footer_infoLine3_kivinetti",
};

const cooperativeTranslations = {
  firmNameTerm: "footer_firmName_cooperative",
  infoline1: "footer_infoLine1_cooperative",
  infoline2: "footer_infoLine2_cooperative",
  infoline3: "footer_infoLine3_cooperative",
};

const Footer = () => {
  const classes = useStyles();

  const { operationUnit } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("xs"));

  const [isCooperativeContent, setIsCooperativeContent] =
    useState<boolean>(false);

  useEffect(() => {
    setIsCooperativeContent(productionLine === ProductionArea.Cooperative);
  }, [productionLine]);

  const usegeTermsLinkJsx = () => (
    <Link
      href={"https://www.hkagri.fi/kayttoehdot/"}
      target="_blank"
      style={{ color: "#fff" }}>
      {t("footer_usage_terms")}
    </Link>
  );

  const getRegularFooterJsx = () => {
    const currentTranslations =
      operationUnit === OperationUnit.Kivikyla
        ? kivinettiTranslations
        : sinettiTranslations;

    const currentSocialMediaLinks =
      operationUnit === OperationUnit.Kivikyla
        ? kivinettiSocialMediaLinks
        : hkscanSocialMediaLinks;

    const logo =
      operationUnit === OperationUnit.Kivikyla ? (
        <img src={KivinettiLogo} className={classes.kivinettiLogo} alt="" />
      ) : (
        <Logo className={classes.logo} />
      );

    const environmentLeft =
      operationUnit === OperationUnit.Kivikyla ? (
        <img
          src={KivinettiEnvironmentLeft}
          className={classes.kivinettiEnvironmentLeft}
          alt=""
        />
      ) : (
        <HKScanEnvironmentLeft className={classes.hkscanEnvironmentLeft} />
      );

    const environmentRight =
      operationUnit === OperationUnit.Kivikyla ? (
        <img
          src={KivinettiEnvironmentRight}
          className={classes.kivinettiEnvironmentRight}
          alt=""
        />
      ) : (
        <HKScanEnvironmentRight className={classes.hkscanEnvironmentRight} />
      );

    return (
      <Grid container spacing={5} alignItems="flex-start">
        <Hidden xsDown>
          <Grid item sm={3} md={4}>
            {environmentLeft}
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.logoContainer}>{logo}</div>
          <div>
            <strong>{t(currentTranslations.firmNameTerm)}</strong>
          </div>
          <div>
            {t(currentTranslations.infoline1)}
            <br />
            {t(currentTranslations.infoline2)}
            <br />
            {t(currentTranslations.infoline3)}
          </div>
          <br />
          {usegeTermsLinkJsx()}
          <div className={classes.icons}>
            {currentSocialMediaLinks.map((item, index) => (
              <a
                href={item.link}
                target={"_blank"}
                key={index}
                rel="noreferrer">
                {cloneElement(item.icon, { height: 30, width: 30 })}
              </a>
            ))}
          </div>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={3} md={4}>
            {environmentRight}
          </Grid>
        </Hidden>
      </Grid>
    );
  };

  const getCooperativeFooterJsx = () => (
    <Grid container spacing={2} alignItems="flex-start">
      <Hidden xsDown>
        <Grid item sm={6}>
          <img
            src={CooperativeFooterImage}
            className={classes.cooperativeFooterImage}
            alt=""
          />
        </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        className={
          !isSmDown
            ? classes.cooperativeContact
            : classes.cooperativeContactAlone
        }>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <strong>{t(cooperativeTranslations.firmNameTerm)}</strong>
          </Grid>
          <Grid item xs={12}>
            {t(cooperativeTranslations.infoline1)}
          </Grid>
          <Grid item xs={12}>
            {t(cooperativeTranslations.infoline2)}
            {" | "}
            <Link
              href={"https://www.lso.fi"}
              target="_blank"
              style={{ color: "#fff" }}>
              {"www.lso.fi"}
            </Link>
          </Grid>
          <Grid item xs={12}>
            {usegeTermsLinkJsx()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Container id="footer" maxWidth={"xl"} className={classes.footer}>
      {!isCooperativeContent
        ? getRegularFooterJsx()
        : getCooperativeFooterJsx()}
    </Container>
  );
};

export default Footer;
