import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { OperationUnit } from "../../enums/CoreEnums";
import navHelpers, {
  getAllowedProductionAreas,
} from "../../helpers/navHelpers";
import { withAuth } from "../../hocs/withAuth";
import lang, { t } from "../../services/langService";
import * as api from "../../store/apiActions";
import { RootState } from "../../store/rootReducer";
import { langCodeUpdated } from "../../store/ui/i18n";
import {
  operationUnitUpdated,
  producerNumberUpdated,
  productionLineUpdated,
} from "../../store/ui/settings";

const AppInit = ({ children }) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const { langCode, translations, supportedLanguages } = useSelector(
    (state: RootState) => state.ui.i18n
  );
  const { producerNumber, productionLine, operationUnit } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const { organizations, roleMap } = useSelector(
    (state: RootState) => state.user
  );

  const lastVisitedOrganization = useSelector(
    (state: RootState) => state.user.lastVisitedOrganization
  );
  const lastVisitedOrganizationChecked = useSelector(
    (state: RootState) => state.user.lastVisitedOrganizationChecked
  );

  const dispatch = useDispatch();
  const location = useLocation();
  // used to force component to render
  const [language, setLanguage] = useState("");
  const [
    defaultOrganizationSelected,
    setDefaultOrganizationSelected,
  ] = useState(false);

  const initTranslation = async () => {
    await lang.init(
      {
        lang: langCode,
        debug: false,
      },
      false,
      translations
    );
  };

  // set page title based on operation unit
  document.title =
    operationUnit === OperationUnit.Kivikyla
      ? "Kivikylän | Kivinetti"
      : "HKFoods | Sinetti";

  useEffect(() => {
    if (currentUser.authenticationSource) {
      dispatch(api.ui.getValidationRules());
      dispatch(api.ui.getTranslations());
      dispatch(api.user.getUserRoleMaps());
      dispatch(api.user.getUserLastVisitedOrganization());
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      currentUser.authenticationSource &&
      currentUser.authenticationSource !== "HKScanAzureAD"
    ) {
      dispatch(api.user.getUserOrganizations());
    }
  }, [currentUser, lastVisitedOrganizationChecked, lastVisitedOrganization]);

  useEffect(() => {
    if (
      !currentUser.authenticationSource ||
      currentUser.authenticationSource !== "HKScanAzureAD" ||
      !lastVisitedOrganizationChecked
    ) {
      return;
    }
    if (!lastVisitedOrganization) {
      dispatch(api.user.getUserOrganizations());
    } else {
      dispatch(
        api.user.getUserFilteredOrganizations(
          currentUser.authenticationSource,
          lastVisitedOrganization.toString()
        )
      );
    }
  }, [currentUser, lastVisitedOrganizationChecked, lastVisitedOrganization]);

  useEffect(() => {
    if (translations && Object.keys(translations).length > 0) {
      initTranslation();
      let selectedLang = lang.detectBrowserLanguage();
      if (supportedLanguages.indexOf(selectedLang) === -1) selectedLang = "fi";
      dispatch(langCodeUpdated({ langCode: selectedLang }));
    }
  }, [translations]);

  useEffect(() => {
    if (langCode) {
      lang.changeLanguage(langCode).then(() => {
        const title: any = document.getElementById("app-title");
        title.innerHTML = t("app_name");
        moment.locale(langCode);
        setLanguage(langCode);
        setInitialized(true);
      });
    }
  }, [langCode]);

  useEffect(() => {
    if (
      organizations.length > 0 &&
      currentUser.authenticationSource === "HKScanAzureAD" &&
      !defaultOrganizationSelected
    ) {
      dispatch(
        producerNumberUpdated({
          producerNumber: organizations[0].producerNumber,
        })
      );
      dispatch(
        operationUnitUpdated({
          operationUnit: organizations[0].operationUnit,
        })
      );

      setDefaultOrganizationSelected(true);
    }
  }, [organizations, currentUser, producerNumber]);

  useEffect(() => {
    if (
      organizations.length > 0 &&
      producerNumber &&
      lastVisitedOrganization &&
      producerNumber !== lastVisitedOrganization
    ) {
      dispatch(
        producerNumberUpdated({
          producerNumber: lastVisitedOrganization,
        })
      );
      dispatch(
        operationUnitUpdated({
          operationUnit: organizations.find(
            (org) => org.producerNumber === lastVisitedOrganization
          )?.operationUnit,
        })
      );
    }
  }, [producerNumber, lastVisitedOrganization, organizations]);

  useEffect(() => {
    if (
      lastVisitedOrganization &&
      currentUser &&
      currentUser.authenticationSource === "HKScanAzureAD" &&
      organizations &&
      organizations.length > 0 &&
      organizations[0].producerNumber !== lastVisitedOrganization
    ) {
      dispatch(
        api.user.getUserFilteredOrganizations(
          currentUser.authenticationSource,
          lastVisitedOrganization.toString()
        )
      );
    }
  }, [currentUser, lastVisitedOrganization, organizations]);

  useEffect(() => {
    if (organizations?.length > 0 && producerNumber) {
      const producer = organizations.find(
        (p) => p.producerNumber == producerNumber
      );
      const productionLines = Object.keys(producer?.productionLines || {});

      const allowedProductionAreas = getAllowedProductionAreas(
        currentUser.roles,
        currentUser.producers,
        producerNumber,
        roleMap
      );

      let selectedProductionLine =
        navHelpers.getProductionLine(location.pathname).toString() ||
        productionLine;

      if (
        productionLines?.length > 0 &&
        productionLines.indexOf(selectedProductionLine) === -1
      ) {
        selectedProductionLine = productionLines[0];
      }

      if (!allowedProductionAreas.includes(selectedProductionLine)) {
        selectedProductionLine = productionLines.filter((productionArea) =>
          allowedProductionAreas.includes(productionArea)
        )[0];
      }

      dispatch(
        productionLineUpdated({ productionLine: selectedProductionLine })
      );
    }
  }, [organizations, producerNumber, roleMap, currentUser]);

  if (!initialized) {
    return <LoadingIndicator showAlwaysLoadingIndicator={true} />;
  }

  return (
    <Fragment key={language}>
      <LoadingIndicator />
      {children}
    </Fragment>
  );
};

export default withAuth(AppInit);
